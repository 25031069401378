<template>
  <div class="col-md-12 p-0">
    <smart-duo v-if="$store.getters['Auth/checkPermission']('bill_show') || $store.getters['Auth/checkPermission']('bill_showonlyforcustomer')"
               filterOptionsUrl="/Bill/Vue"
               customRenderFor="CustomerSummary" :customerId="customerId"></smart-duo>

    <smart-duo v-if="$store.getters['Auth/checkPermission']('accounting_showdebt') || $store.getters['Auth/checkPermission']('accounting_payfromdebt')"
               filterOptionsUrl="/Accounting/VueCustomerDebt"
               customRenderFor="CustomerSummary" :customerId="customerId"></smart-duo>

    <smart-duo v-if="$store.getters['Auth/checkPermission']('accounting_show')"
               filterOptionsUrl="/Accounting/VueCustomerPaid"
               customRenderFor="CustomerSummary" :customerId="customerId"></smart-duo>
  </div>
</template>
<script>
  import SmartDuo from '../Shared/SmartDuo'
  export default {
    name: 'SummaryAccountings',
    props: {
      customerId: String
    },
    components: {
      SmartDuo
    }
  }
</script>
